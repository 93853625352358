import { NotificationEvent } from '~/models/notification-center/types'
import {
  AuthUserMarketplaceUserIds,
  AuthUserSaleRequestInfo,
  User,
  UserBulletin,
  UserLoansInfo,
  UserMarketplaceInfo
} from '~/models/user/types'
import { Pagination } from '~/models/search/types'
import { DEFAULT_NUMBER_OF_RESULTS_PER_PAGE } from '~/constants/search/defaults'
import { AccessToggle } from '~/models/user/access-toggles'
import {
  AuthUserSubscriptionInfo,
  AuthUserStripeSubscriptionInfo
} from '~/models/user/subscription'
import { SubscriptionTier } from '~/models/search/subscriptions/types'

export interface UserState extends User {
  location?: object
  featureToggles: string[]
  accessToggles: AccessToggle[]
  subscription?: AuthUserSubscriptionInfo | null
  stripeSubscription?: AuthUserStripeSubscriptionInfo | null
  notificationRelayUrl?: string
  hasParked: boolean
  perks?: { tier: SubscriptionTier }
  saleRequests?: AuthUserSaleRequestInfo
  marketplaceIds?: AuthUserMarketplaceUserIds
  marketplace?: UserMarketplaceInfo
  bulletins?: UserBulletin[]
  loans?: UserLoansInfo
}

export const USER_NS = 'user'

function getDefaultState(): UserState {
  return {
    avatar: '',
    marketplaceIds: undefined,
    marketplace: undefined,
    id: undefined,
    permissions: [],
    credits: undefined,
    properties: undefined,
    type: undefined,
    username: undefined,
    name: undefined,
    location: undefined,
    featureToggles: [],
    unreadNotifications: 0,
    unreadTradesNotifications: 0,
    unreadParkingNotifications: 0,
    unreadAnnouncements: 0,
    unreadMessages: 0,
    myClassifieds: [],
    xmlIssues: undefined,
    sdmu: false,
    invoicesCount: undefined,
    outletsCount: undefined,
    rogueClassifiedsCount: undefined,
    rogueRequirements: undefined,
    auditRequestsCount: undefined,
    referenceCode: undefined,
    isPayingUser: undefined,
    hasCompleteProfile: undefined,
    externalIds: {},
    notificationsCenter: {
      loading: true,
      notifications: {
        pagination: {
          perPage: DEFAULT_NUMBER_OF_RESULTS_PER_PAGE,
          total: 0
        } as Pagination,
        rows: [] as Array<NotificationEvent>
      },
      notificationTypes: []
    },
    notificationRelayUrl: '',
    accessToggles: [],
    subscription: null,
    stripeSubscription: null,
    hasPublicDealers: undefined,
    loans: undefined,
    inHouseConfig: [],
    telephone: null,
    firstName: null,
    lastName: null,
    email: null,
    hasParked: false,
    saleRequests: undefined,
    hasTickets: undefined,
    bulletins: [],
    relatedToDealers: []
  }
}
export default getDefaultState
