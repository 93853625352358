import { AccountOverview } from '~/models/account'
import { AnalyticsPanelSubPanel } from '~/models/analytics/classifieds'
export const ACCOUNT_NS = 'account'
export interface AccountState {
  loading: boolean
  accountOverview: AccountOverview | null
  quickStatsPanels: AnalyticsPanelSubPanel[]
}
function getDefaultState(): AccountState {
  return {
    loading: false,
    accountOverview: null,
    quickStatsPanels: []
  }
}
export default getDefaultState
