























import { defineComponent, computed, toRefs, ref } from '~/utils/nuxt3-migration'
export default defineComponent({
  props: {
    src: { type: String, default: null },
    fluid: { type: Boolean, default: false },
    alt: { type: String, default: null },
    fluidGrow: { type: Boolean, default: false },
    rounded: { type: [String, Boolean], default: false },
    thumbnail: { type: Boolean, default: false },
    lazy: { type: Boolean, default: false },
    objectFit: { type: String, default: 'initial' },
    draggable: { type: [Boolean, String], default: undefined },
    width: { type: [Number, String], default: null },
    height: { type: [Number, String], default: null }
  },
  setup(props) {
    const { rounded, objectFit, lazy } = toRefs(props)

    const imgTemplateRef = ref(null)

    const roundedClass = computed(() => {
      switch (rounded.value) {
        case 'top': {
          return 'tw-rounded-t'
        }
        case 'right': {
          return 'tw-rounded-r'
        }
        case 'bottom': {
          return 'tw-rounded-b'
        }
        case 'left': {
          return 'tw-rounded-l'
        }
        case 'circle': {
          return 'tw-rounded-full'
        }
        case '': {
          return 'tw-rounded'
        }
        default: {
          return null
        }
      }
    })

    const objectFitClass = computed(() => {
      if (!objectFit.value) {
        return ''
      }
      switch (objectFit.value) {
        case 'contain': {
          return 'tw-object-contain'
        }
        case 'cover': {
          return 'tw-object-cover'
        }
        case 'fill': {
          return 'tw-object-fill'
        }
      }
    })

    const loadingAttr = computed(() => (lazy.value ? 'lazy' : null))

    return {
      roundedClass,
      objectFitClass,
      loadingAttr,
      imgTemplateRef
    }
  }
})
