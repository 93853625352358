import { interopDefault } from '~/router/utils'

export const adminTablesRoutes = [
  {
    name: '__admin_tables_search',
    path: '/admin/tables/',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/admin/tables/tables.vue' /* webpackChunkName: "pages-admin-shared-tables-tables" */
        )
      )
  }
]
