import { ONE_YEAR } from '~/constants/duration'
import { containerScoped } from '~/decorators/dependency-container'
import { clientOnly, serverOnly } from '~/decorators'

import { extractCookie } from '~/utils/cookies'
import { inject } from 'tsyringe'
import {
  configToken,
  requestToken,
  responseToken
} from '~/constants/dependency-injection/tokens'
import { RequestOptions, ServerResponse } from 'http'
import { appendToResponseHeader } from '~/utils/http'
import { HIDE_TRANSFER_CLASSIFIEDS_MODAL } from '~/constants/classified/tranfer-classifieds'

interface CookieSetOptions {
  maxAge?: number
}

@containerScoped()
export default class CookiesService {
  static deletionExpirationAttribute = 'Expires=Thu, 01 Jan 1970 00:00:01 GMT'
  constructor(
    @inject(configToken) private config: any,
    @inject(requestToken) private ssrRequest?: RequestOptions,
    @inject(responseToken) private ssrResponse?: ServerResponse
  ) {}

  public set(
    key: string,
    value: any,
    { maxAge = ONE_YEAR }: CookieSetOptions = {
      maxAge: ONE_YEAR
    },
    forceReplace: boolean = false
  ) {
    if (!key) {
      throw new Error('Attempting to set cookie without a key')
    }
    if (process.server) {
      return this.setCookieOnTheServer(key, value, maxAge, forceReplace)
    } else if (process.client) {
      return this.setCookieOnTheClient(key, value, maxAge)
    }
  }

  public get(key: string): string | null {
    if (!key) {
      throw new Error('Attempting to get cookie without a key')
    }
    if (process.server) {
      return this.getCookieOnTheServer(key)
    } else if (process.client) {
      return this.getCookieOnTheClient(key)
    }
    return null
  }

  public delete(key: string) {
    if (!key) {
      throw new Error('Attempting to delete cookie without a key')
    }
    if (process.server) {
      this.deleteCookieOnTheServer(key)
    } else if (process.client) {
      this.deleteCookieOnTheClient(key)
    }
  }

  public clearSessionCookies(): void {
    // clear any cookies you want to delete after each login/session
    const cookiesToClear = [HIDE_TRANSFER_CLASSIFIEDS_MODAL]

    cookiesToClear.forEach(c => {
      if (this.get(c)) {
        this.delete(c)
      }
    })
  }

  @serverOnly
  private deleteCookieOnTheServer(key: string) {
    const {
      public: { domain }
    } = this.config

    if (this.ssrResponse) {
      appendToResponseHeader(this.ssrResponse, 'set-cookie', [
        `${key}=; Path=/; ${CookiesService.deletionExpirationAttribute}; Domain=${domain}`
      ])
    }
  }

  @serverOnly
  private getCookieOnTheServer(key: string): string | null {
    return (
      (this.ssrRequest && extractCookie(key, this.ssrRequest.headers)) || null
    )
  }

  @serverOnly
  private setCookieOnTheServer(
    key: string,
    value: any,
    maxAge: number,
    forceReplace: boolean = false
  ) {
    if (!this.ssrRequest || !this.ssrResponse) {
      return
    }

    const {
      public: { domain }
    } = this.config

    appendToResponseHeader(
      this.ssrResponse,
      'set-cookie',
      [
        `${key}=${encodeURIComponent(
          value
        )}; Path=/; Max-Age=${maxAge}; Domain=${domain}`
      ],
      forceReplace
    )
  }

  @clientOnly
  private deleteCookieOnTheClient(key: string) {
    if (document) {
      const {
        public: { domain }
      } = this.config

      document.cookie = `${key}=; Path=/; ${CookiesService.deletionExpirationAttribute}; Domain=${domain}`
      document.cookie = `${key}=; Path=/; ${CookiesService.deletionExpirationAttribute};`
      document.cookie = `${key}=; Path=/; ${CookiesService.deletionExpirationAttribute}; Domain=${window.location.hostname}`
    }
  }

  @clientOnly
  private getCookieOnTheClient(key: string): string | null {
    return document && extractCookie(key, document)
  }

  @clientOnly
  private setCookieOnTheClient(key: string, value: any, maxAge: number) {
    if (document) {
      const {
        public: { domain }
      } = this.config

      document.cookie = `${key}=${value}; Path=/; Max-Age=${maxAge}; Domain=${domain}`
    }
  }
}
