export function getLiveStaticUrl(path: string): string {
  return `https://static.car.gr${path}`
}

export function getStaticUrl(path: string): string {
  if (
    process.env.ENVIRONMENT === 'staging' ||
    process.env.NODE_ENV === 'development'
  ) {
    return path
  }

  return `https://static-cz.car.gr${path}`
}
