































import {
  defineComponent,
  computed,
  PropType,
  useRoute
} from '~/utils/nuxt3-migration'
import { ErrorPageError } from '~/models/error'
import { HttpStatus } from '~/constants/http'
import error404Image from '~/assets/img/errors/404.png'
import RecentFeed from '~/components/car/classifieds/index/feeds/RecentFeed.vue'
import { getRouteMetaItem } from '~/utils/router'
import { useUserAgent } from '~/compositions/user-agent'

export default defineComponent({
  components: { RecentFeed },
  props: {
    error: {
      type: Object as PropType<ErrorPageError>,
      required: true
    }
  },
  setup(props) {
    const { isPc } = useUserAgent()
    const route = useRoute()

    const errorImage = computed(() => {
      const { statusCode } = props.error
      switch (statusCode) {
        case HttpStatus.NOT_FOUND: {
          return error404Image
        }
        default: {
          return null
        }
      }
    })
    // TODO: move this to the store
    const isIframe = computed(() => {
      return getRouteMetaItem(route.value, 'isIframe')
    })
    const isSubdomain = computed(() => {
      return getRouteMetaItem(route.value, 'isSubdomain')
    })
    const showRecentFeed = computed(() => {
      return (
        !isIframe.value &&
        ![
          HttpStatus.TOO_MANY_REQUESTS,
          HttpStatus.INTERNAL_SERVER_ERROR,
          HttpStatus.BAD_GATEWAY,
          HttpStatus.UNAUTHORIZED,
          HttpStatus.FORBIDDEN
        ].includes(props.error.statusCode)
      )
    })
    const showGoBack = computed(() => {
      return !isIframe.value && !isSubdomain.value
    })

    return {
      errorImage,
      isPc,
      showRecentFeed,
      showGoBack
    }
  }
})
