import { GetterTreeWithRootState } from '~/store/types'
import { AccountState } from '~/store/modules/shared/account/state'
import { StripeBalance, StripeSeller } from '~/models/payments/stripe'
import {
  AccountOverview,
  ClassifiedsState,
  ClassifiedsVisibilityStates
} from '~/models/account'
import { AnalyticsPanelSubPanel } from '~/models/analytics/classifieds'
import { Classified } from '~/models/classified/types'
import { AnnouncementEntry } from '~/models/announcement/types'

export default {
  getLoading(state): boolean {
    return state.loading
  },
  getAccountOverview(state): AccountOverview | null {
    return state.accountOverview
  },
  getClassifiedsCount(state): number | null {
    if (
      state.accountOverview?.classifiedsCount ||
      state.accountOverview?.classifiedsCount === 0
    ) {
      return state.accountOverview?.classifiedsCount
    }
    return null
  },
  getClassifiedsColorsStates(state): ClassifiedsState[] | null {
    return state.accountOverview?.classifiedsStates || null
  },
  getQuickStatsFetchUrl(state): string | null {
    return state.accountOverview?.quickStats?.fetchUrl || null
  },
  getTicketsCount(state): number | null {
    if (
      state.accountOverview?.tickets ||
      state.accountOverview?.tickets === 0
    ) {
      return state.accountOverview?.tickets
    }
    return null
  },
  getFailedAuditsCount(state): number | null {
    if (
      state.accountOverview?.failedCount ||
      state.accountOverview?.failedCount === 0
    ) {
      return state.accountOverview?.failedCount
    }
    return null
  },
  getPendingAuditsCount(state): number | null {
    if (
      state.accountOverview?.pendingCount ||
      state.accountOverview?.pendingCount === 0
    ) {
      return state.accountOverview?.pendingCount
    }
    return null
  },
  getCompletedAuditsCount(state): number | null {
    if (
      state.accountOverview?.completedCount ||
      state.accountOverview?.completedCount === 0
    ) {
      return state.accountOverview?.completedCount
    }
    return null
  },
  getAuditCertificatesCount(state): number | null {
    if (
      state.accountOverview?.certificatesCount ||
      state.accountOverview?.certificatesCount === 0
    ) {
      return state.accountOverview?.certificatesCount
    }
    return null
  },
  getQuickStatsPanels(state): AnalyticsPanelSubPanel[] {
    return state.quickStatsPanels.filter(p => p.type !== 'classified_states')
  },
  getClassifiedsStatesPanel(state): ClassifiedsVisibilityStates | undefined {
    return state.accountOverview?.classifiedsVisibilityStates
  },
  getHasPublicDealers(state): boolean | undefined {
    return state.accountOverview?.hasPublicDealers
  },
  getPromotedClassifieds(state): Classified[] | null {
    return state.accountOverview?.promotedClassifieds || null
  },
  getRecentAnnouncements(state): AnnouncementEntry[] | null {
    return state.accountOverview?.recentAnnouncements || null
  },
  getStripeSeller(state): StripeSeller | undefined {
    return state.accountOverview?.stripeSeller
  },
  getAuditsBalance(state): StripeBalance | null {
    return state.accountOverview?.stripeSeller?.accountBalance || null
  }
} as GetterTreeWithRootState<AccountState>
