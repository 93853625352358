export interface Subscription {
  id: string
  searchId: string
  lastSearchDate: string
  creationDate: string
  sendOptions: {
    type?: PushType
  }
  unseenCount: number
}

export enum PushType {
  INSTANT = 'instant',
  BULK = 'bulk'
}

export interface CountMeasurement {
  count: number
  date: string
}

export interface SubscriptionStats {
  average: number
  days: Array<CountMeasurement>
}

export interface SubscriptionNotificationInfo {
  instantValid: boolean
  instantAverageLimit: number
  bulkValid: boolean
  bulkAverageLimit: number
}

export interface SubscriptionInfo {
  searchId: string
  notifications: SubscriptionNotificationInfo
  // Stats for results' counts.
  stats: SubscriptionStats
  totalSubscriptions: number
  maxSubscriptions: number
}

export interface SubscriptionCounts {
  total: number
  max: number
}

export enum SubscriptionTier {
  BRONZE = 'dealer_bronze',
  SILVER = 'dealer_silver',
  GOLD = 'dealer_gold'
}
