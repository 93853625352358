import { interopDefault } from '~/router/utils'

export default [
  {
    name: '__dealers',
    path: '/dealers',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/dealers/index.vue' /* webpackChunkName: "pages-dealers-index.vue" */
        )
      )
  },
  {
    name: '__dealers_list',
    path: '/dealers/list',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/dealers/list.vue' /* webpackChunkName: "pages-dealers-list.vue" */
        )
      )
  },
  {
    name: '__dealers_map',
    path: '/dealers/map',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/dealers/map.vue' /* webpackChunkName: "pages-dealers-map.vue" */
        )
      ),
    meta: { noFooter: true }
  },
  {
    name: '__managers_registration_info',
    path: '/dealers/registration-info',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/managers/registration-info.vue' /* webpackChunkName: "pages-managers-registration-info.vue" */
        )
      )
  }
]
