export const SET_USER_DATA = 'SET_USER_DATA'
export const SET_AVATAR = 'SET_AVATAR'
export const SET_USER_EXTRA_DATA = 'SET_USER_EXTRA_DATA'
export const SET_CREDITS = 'SET_CREDITS'
export const SET_USER_NOTIFICATION_CENTER_DATA =
  'SET_USER_NOTIFICATION_CENTER_DATA'
export const SET_USER_NOTIFICATION_CENTER_LOADING_STATE =
  'SET_USER_NOTIFICATION_CENTER_LOADING_STATE'
export const CLEAR_NOTIFICATION_CENTER_DATA = 'CLEAR_NOTIFICATION_CENTER_DATA'
export const TRANSFER_ROGUE_CLASSIFIED = 'TRANSFER_ROGUE_CLASSIFIED'
export const INCREASE_UNREAD_NOTIFICATIONS = 'INCREASE_UNREAD_NOTIFICATIONS'
export const DECREASE_UNREAD_NOTIFICATIONS = 'DECREASE_UNREAD_NOTIFICATIONS'

export const INCREASE_UNREAD_MESSAGES = 'INCREASE_UNREAD_MESSAGES'
export const DECREASE_UNREAD_MESSAGES = 'DECREASE_UNREAD_MESSAGES'
export const READ_ALL_CURRENT_NOTIFICATION_CENTER_ROWS =
  'READ_ALL_CURRENT_NOTIFICATION_CENTER_ROWS'

export const ADD_NOTIFICATION_CENTER_ROW = 'ADD_NOTIFICATION_CENTER_ROW'

export const SET_HAS_PARKED = 'SET_HAS_PARKED'
export const READ_TICKETS = 'READ_TICKETS'
export const SET_HAS_SALE_REQUESTS_ITEMS = 'SET_HAS_SALE_REQUESTS_ITEMS'
export const DECREASE_UNREAD_ANNOUNCEMENTS = 'DECREASE_UNREAD_ANNOUNCEMENTS'
export const SET_MARKETPLACE_ONBOARDING_BALANCE_STATE =
  'SET_MARKETPLACE_ONBOARDING_BALANCE_STATE'
export const INCREMENT_MARKETPLACE_BUYER_CART_ITEMS_COUNT =
  'INCREMENT_MARKETPLACE_BUYER_CART_ITEMS_COUNT'
export const DECREMENT_MARKETPLACE_BUYER_CART_ITEMS_COUNT =
  'DECREMENT_MARKETPLACE_BUYER_CART_ITEMS_COUNT'
