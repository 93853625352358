import { MutationTree } from 'vuex'
import { AccountState } from './state'
import { createMutationCreator } from '~/factories/store/mutation'
import {
  SET_ACCOUNT_LOADING,
  SET_ACCOUNT_OVERVIEW,
  SET_AGENT_QUICK_STATS_PANELS,
  SET_ACCOUNT_OVERVIEW_STRIPE_SELLER
} from '~/store/modules/shared/account/mutation-types'
import { StripeSeller } from '~/models/payments/stripe'
import { vue3Set } from '~/utils/nuxt3-migration'

const { setter } = createMutationCreator<AccountState>()

export default {
  ...setter(SET_ACCOUNT_LOADING, 'loading'),
  ...setter(SET_ACCOUNT_OVERVIEW, 'accountOverview'),
  ...setter(SET_AGENT_QUICK_STATS_PANELS, 'quickStatsPanels'),
  [SET_ACCOUNT_OVERVIEW_STRIPE_SELLER](state, stripeSeller: StripeSeller) {
    if (state.accountOverview) {
      vue3Set(state.accountOverview, 'stripeSeller', stripeSeller)
    }
  }
} as MutationTree<AccountState>
